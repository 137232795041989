<template>
    <v-dialog
            v-model="showAdd"
            width="500"
            persistent
            @keydown.esc="onClose"
            @keydown.enter="save"
    >
        <template v-slot:activator="{ on }">
            <v-btn @click="onNewClicked" color="secondary" dark v-on="on">
                {{ $vuetify.breakpoint.mobile ? '+' : $t("createCancellationReason") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 bg-prosk-secondary white--text">
                <span class="headline">
          {{ item.isEdit ? $t("editCancellationReason") : $t("createCancellationReason") }}
        </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-col cols="12">
                        <v-text-field
                                :label="$t('adminCancellationReason')"
                                v-model="reason"
                                color="#59D79D"
                                :hide-details="reasonError.length === 0"
                                :error-count="reasonError.length"
                                :error-messages="reasonError"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            :items="usersType"
                            :label="$t('typeUser')"
                            color="#59D79D"
                            item-value="id"
                            item-text="type_user"
                            v-model="type_user"
                        >
                            <template #selection="{ item }">
                                <v-chip color="#59D79D" class="white--text">{{item.type_user}}</v-chip>
                            </template>
                        </v-select>
                    </v-col>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <cancel-button :cancel="onClose" :message="$t('cancel')"/>
                <save-button :save="save" :loading="loading" :message="$t('save')"/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
    name: 'AdminCancellationReason',
    components: { CancelButton, SaveButton },
    props: ['showAdd', 'onClose', 'item', 'newItem', 'onAdded'],
    mixins: [errorDialog],
    data () {
        return {
            loading: false,
            reason: null,
            type_user: 0,
            usersType: [
                {
                    id: 0,
                    type_user: this.$t('client')
                },
                {
                    id: 10,
                    type_user: this.$t('prosker')
                }
            ],
            reasonError: []
        };
    },
    watch: {
        showAdd: function (value) {
            if (value && this.item && this.item.isEdit) {
                this.reason = this.item.reason;
                this.type_user = this.item.type_user;
                this.isEdit = this.item.isEdit;
            }
        }
    },
    methods: {
        onNewClicked () {
            this.resetErrors();
            this.reset();
            this.newItem();
        },
        reset () {
            this.reason = null;
            this.type_user = 0;
        },
        resetErrors () {
            this.reasonError = [];
        },
        save () {
            this.resetErrors();
            let error = false;
            const formData = {
                reason: this.reason,
                type_user: this.type_user
            };
            if (!this.reason) {
                this.reasonError.push(this.$t('fieldReasonRequired'));
                error = true;
            }
            if (!error) {
                this.loading = true;
                if (!this.item.isEdit) {
                    const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons`;
                    axios
                        .post(url, formData)
                        .then(() => {
                            this.loading = false;
                            this.onClose();
                            this.onAdded();
                            this.reset();
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.showError(error);
                        });
                } else {
                    const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons/${this.item.id}`;
                    axios
                        .patch(url, formData)
                        .then(() => {
                            this.reset();
                            this.loading = false;
                            this.onClose();
                            this.onAdded();
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.showError(error);
                        });
                }
            }
        }
    }
};
</script>
