<template>
    <v-main class="container-prosker">
        <delete-warning
                :confirm-delete="confirmDelete"
                :on-close="onDeleteClose"
                :show-delete="showDelete"
                :loading="loading"
        />
        <v-data-table
                :options.sync="pagination"
                :server-items-length="pagination.totalItems"
                :loading="loadingTable"
                :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
                :search="search" :headers="headers"
                :items="data"
                class="elevation-1 mt-5"
                item-key="id"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('adminCancellationReason') }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field :label="$t('search')" append-icon="search" hide-details
                                  single-line v-model="search" color="#59D79D"></v-text-field>
                    <v-spacer></v-spacer>
                    <admin-cancellation-reason
                            :item="editedItem"
                            :on-close="onAddClose"
                            :on-added="onAddCallback"
                            :new-item="newItem"
                            :show-add="showAdd"
                    />
                </v-toolbar>
            </template>
            <template v-slot:no-data>
                <v-alert
                        :value="true"
                        color="error"
                        icon="warning"
                        class="white--text"
                >
                        <span class="white--text">
                            {{ $t("noData") }}
                        </span>
                </v-alert>
            </template>
            <template v-slot:item.type_user="{ item }">
                <span> {{ getTypeUser(item.type_user) }} </span>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                        <span class="white--text">
                            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
                        </span>
                </v-alert>
            </template>
            <template v-slot:item.action="{ item }">
                <tooltip-button
                        :icon="'mdi-pencil'"
                        :action="editItem"
                        :item="item"
                        :color="'secondary'"
                        :message="'edit'"
                ></tooltip-button>
                <tooltip-button
                        :icon="'mdi-delete'"
                        :action="openWarning"
                        :item="item.id"
                        :color="'red'"
                        :message="'delete'"
                ></tooltip-button>
            </template>
        </v-data-table>
    </v-main>
</template>
<script>
import axios from 'axios';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import _ from 'lodash';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import AdminCancellationReason from '@/components/socialvue/dialogs/AdminCancellationReason.vue';
export default {
    name: 'CancellationReason',
    components: { AdminCancellationReason, DeleteWarning, TooltipButton },
    mixins: [errorDialog],
    data () {
        return {
            data: [],
            showDelete: false,
            itemToDelete: null,
            showAdd: false,
            loading: false,
            pagination: { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] },
            loadingTable: false,
            search: '',
            editedItem: {}
        };
    },
    created () {
        this.list();
    },
    computed: {
        headers () {
            return [
                { text: this.$t('adminCancellationReason'), value: 'reason' },
                { text: this.$t('typeUser'), value: 'type_user' },
                { text: this.$t('actions'), value: 'action', sortable: false }
            ];
        }
    },
    watch: {
        search: {
            handler () {
                this.resetPaginationForSearch(this);
            },
            deep: true
        },
        pagination: {
            handler () {
                this.list();
            },
            deep: true
        }
    },
    methods: {
        getTypeUser (user) {
            if (user === 0) {
                return this.$t('client');
            } else if (user === 10) {
                return this.$t('prosker');
            }
        },
        confirmDelete () {
            this.loading = true;
            const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons/${this.itemToDelete}`;
            axios.delete(url).then(() => {
                this.list();
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.showError(error);
            });
            this.showDelete = false;
        },
        onDeleteClose () {
            this.showDelete = false;
        },
        openWarning (item) {
            this.showDelete = true;
            this.itemToDelete = item;
        },
        resetPagination () {
            this.pagination = { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] };
        },
        resetPaginationForSearch: _.debounce((self) => {
            self.resetPagination();
        }, 500),
        onAddClose () {
            this.showAdd = false;
            this.reset();
            this.editedItem.isEdit = false;
        },
        onAddCallback () {
            this.list();
        },
        reset () {
            this.editedItem = {};
        },
        newItem () {
            this.reset();
            this.showAdd = true;
            this.editedItem.isEdit = false;
        },
        editItem (item) {
            this.editedItem = item;
            this.editedItem.isEdit = true;
            this.showAdd = true;
        },
        list () {
            this.loadingTable = true;
            const url = `${process.env.VUE_APP_BASE_URL}/api/job-cancellation-reasons?search=${this.search}
      &per_page=${this.pagination.per_page}&page=${this.pagination.page}&sortBy=${this.pagination.sortBy[0]}`;
            axios.get(url).then((response) => {
                this.data = [];
                this.data = response.data.data;
                this.pagination.totalItems = response.data.total;
                this.loadingTable = false;
            }).catch((error) => {
                this.loadingTable = false;
                this.showError(error);
            });
        }
    }
};
</script>
